/*
 * @Description:
 * @Autor: 郝志杰
 * @Date: 2023-02-15 11:29:51
 * @LastEditors: 郝志杰
 * @LastEditTime: 2025-04-01 14:06:21
 */
import axios from 'axios';
import { Toast } from 'vant';

// 接口环境地址
let API_ROOT = 'https://efficiency.hive-df.com/everStandard/'; // 生产环境
// let API_ROOT = 'http://ef.hive-df.com/everStandard/'; // 测试环境
// http://ef.hive-df.com/everStandard/
// let API_ROOT = 'http://10.20.11.24:50494'; // TODO: 开发环境，布署时删除此代码


// 超时设定
axios.defaults.timeout = 15000;
// 跨域访问携带cookie
axios.defaults.withCredentials = true;

axios.interceptors.request.use(config => {
    return config;
}, err => {
    Toast('请求超时');
    return Promise.resolve(err);
});

// http response 拦截器
axios.interceptors.response.use(response => {
    const data = response.data;
    // 根据返回的code值来做不同的处理(和后端约定)
    switch (data.code) {
        case 210:
            Toast(data.message);
            break;
        // 错误
        case 500:
            Toast('联系管理员');
            break;
        default:
            return data;
    }
    return data;
}, (err) => {
    // 返回状态码不为200时候的错误处理
    Toast('连接错误，请重试');
    return Promise.resolve(err);
});

// get 请求
export const getRequest = (url, params) => {
    return axios({
        method: 'get',
        url: `${API_ROOT}${url}`,
        params: params

    });
};

// post请求
export const postRequest = (url, params) => {
    return axios({
        method: 'post',
        url: `${API_ROOT}${url}`,
        data: params,
        headers: {
            'Content-Type': 'application/json'
        }
    });
};


