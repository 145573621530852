
<!--
 * @Description:試卷主頁面
 * @Autor: 郝志杰
 * @Date: 2021-10-27 15:12:45
 * @LastEditors: 郝志杰
 * @LastEditTime: 2025-04-01 14:02:13
-->
<template>
    <div class="main">
        <div class="btn-wrap" v-if="!resultFlag">
            <van-button type="info" size="normal" class="radius-btn" v-show="showConfirmBtn" @click="confirm" :disabled="currentIndexArr.length == 0 ">确&nbsp;认</van-button>
            <van-button type="info" size="normal" class="radius-btn" v-show="!showConfirmBtn" @click="nextTitle">下一题</van-button>
        </div>
        <div class="btn-wrap" v-else>
            <van-button type="info" size="normal" class="radius-btn" @click="showResult">完&nbsp;成</van-button>
        </div>
        <div class="content">
            <div class="question-box">
                <!-- 标题 -->
                <div class="question-header">
                    <div class="question-header-left"> 题目</div>
                    <div>
                        <b class="index">{{ nowIndex }}</b>
                        <span class="total">/10</span>
                    </div>
                </div>
                <!-- 题目描述 -->
                <div v-for="(item,index) in questionArr" :key="item.id">
                    <template v-if="index + 1 == nowIndex">
                        <div class="question-desc">
                            {{ item.questionName }}
                        </div>
                        <!-- 答案区 -->
                        <div class="question-answer">
                            <ul v-if="item.types !==3">
                                <li @click="checkAnswer(item,0,'A')" class="info "
                                    :class="{'current-info':currentIndexArr.includes(0),
                                             'right-answer':item.answerA=='right',
                                             'wrong-answer':item.answerA=='wrong'
                                    }">
                                    <!-- 正确 -->
                                    <van-icon name="passed" v-if="item.answerA=='right'" />
                                    <!-- 错误 -->
                                    <van-icon name="close" v-if="item.answerA=='wrong'" />
                                    <!-- 题目描述 -->
                                    <span>A.{{ item.optiona }}</span>
                                </li>
                                <li @click="checkAnswer(item,1,'B')" class="info "
                                    :class="{'current-info':currentIndexArr.includes(1),
                                             'right-answer':item.answerB=='right',
                                             'wrong-answer':item.answerB=='wrong'
                                    }">
                                    <!-- 正确 -->
                                    <van-icon name="passed" v-if="item.answerB=='right'" />
                                    <!-- 错误 -->
                                    <van-icon name="close" v-if="item.answerB=='wrong'" />
                                    <!-- 题目描述 -->
                                    B.{{ item.optionb }}
                                </li>
                                <li @click="checkAnswer(item,2,'C')" class="info "
                                    :class="{'current-info':currentIndexArr.includes(2),
                                             'right-answer':item.answerC=='right',
                                             'wrong-answer':item.answerC=='wrong'
                                    }">
                                    <!-- 正确 -->
                                    <van-icon name="passed" v-if="item.answerC=='right'" />
                                    <!-- 错误 -->
                                    <van-icon name="close" v-if="item.answerC=='wrong'" />
                                    <!-- 题目描述 -->
                                    C.{{ item.optionc }}
                                </li>
                                <li @click="checkAnswer(item,3,'D')" class="info "
                                    :class="{'current-info':currentIndexArr.includes(3),
                                             'right-answer':item.answerD=='right',
                                             'wrong-answer':item.answerD=='wrong'
                                    }">
                                    <!-- 正确 -->
                                    <van-icon name="passed" v-if="item.answerD=='right'" />
                                    <!-- 错误 -->
                                    <van-icon name="close" v-if="item.answerD=='wrong'" />
                                    <!-- 题目描述 -->
                                    D.{{ item.optiond }}
                                </li>
                            </ul>
                            <ul v-else>
                                <li @click="checkAnswer(item,0,'A')" class="info "
                                    :class="{'current-info':currentIndexArr.includes(0),
                                             'right-answer':item.answerA=='right',
                                             'wrong-answer':item.answerA=='wrong'
                                    }">
                                    <!-- 正确 -->
                                    <van-icon name="passed" v-if="item.answerA=='right'" />
                                    <!-- 错误 -->
                                    <van-icon name="close" v-if="item.answerA=='wrong'" />
                                    <!-- 题目描述 -->
                                    A.正确
                                </li>
                                <li @click="checkAnswer(item,1,'B')" class="info "
                                    :class="{'current-info':currentIndexArr.includes(1),
                                             'right-answer':item.answerB=='right',
                                             'wrong-answer':item.answerB=='wrong'
                                    }">
                                    <!-- 正确 -->
                                    <van-icon name="passed" v-if="item.answerB=='right'" />
                                    <!-- 错误 -->
                                    <van-icon name="close" v-if="item.answerB=='wrong'" />
                                    <!-- 题目描述 -->
                                    B.错误
                                </li>
                            </ul>
                        </div>

                    </template>

                </div>

            </div>
        </div>
        <!-- 解析区 -->
        <div class="analysis" v-if="!isOk">
            <template>
                <!-- <div class="analysis-line"></div> -->
                <div class="analysis-header">
                    <div class="analysis-header-left">答案解析</div>
                </div>
                <div class="analysis-content">
                    <b class="analysis-content-right">正确答案：{{ nowTitle.answer }}</b>
                    <p>
                        {{ nowTitle.analysis }}
                    </p>
                </div>
            </template>

        </div>

        <van-overlay :show="overlayShow">
            <div class="wrapper">
                <van-loading type="spinner" />
            </div>
        </van-overlay>
        <!-- 答题结束 -->
        <van-overlay :show="resultDialog">
            <div class="result">
                <div class="result-header">
                    <div>今日答对题目数</div>
                    <div class="result-header-info">{{ rightNum }}</div>
                </div>
                <div class="result-info">
                    <div>
                        <p>正确率：{{ (rightNum / 10) * 100 + '%' }}</p>
                        <p>错题数：{{ 10- rightNum }}</p>
                    </div>
                    <div>
                        <p>得分：{{ rightNum* 10 }}</p>
                        <p>用时：{{ timeSpent }}秒</p>
                    </div>
                </div>
            </div>
        </van-overlay>
    </div>
</template>
<script>

import { getPaper, saveAnswer, getUse } from '@/http/api.js';
import { Dialog } from 'vant';
export default {
    name: 'paper',
    props: {
        msg: String
    },
    data() {
        return {
            currentIndexArr: [], // 存储点击答案的索引
            userId: '', // 用户id
            user: {}, // 用户信息
            questionArr: [], // 试题
            startTime: '', // 开始答题时间
            endTime: '', // 结束答题时间
            overlayShow: false, // 加载框
            nowIndex: 1, // 当前题目索引
            nowTitle: {},// 当前试题
            nowAnswerArr: [], // 当前选择的答案
            showConfirmBtn: true, // 是否显示确定按钮
            isOk: true, // 是否答对
            rightNum: 0, // 答对的次数
            resultDialog: false, // 得分弹框
            todays: '',
            timeSpent: '', // 用时
            resultFlag: false // 显示查看答案的按钮
        };
    },
    created() {
        this.overlayShow = true;
        // 获取今天;
        let date = new Date();
        let nowMonth = date.getMonth() + 1;
        let nowDay = date.getDate();
        let nowYear = date.getFullYear();
        this.todays = nowYear + '-' + (nowMonth < 10 ? '0' + nowMonth : nowMonth) + '-' +
                (nowDay < 10 ? '0' + nowDay : nowDay);
        // 获取code值
        let url = window.document.location.search;
        let code = url.substring(url.indexOf('=') + 1, url.indexOf('&state'));
        // let use = JSON.parse(window.sessionStorage.getItem('use'));
        // alert(code);
        let use = {
            department: '组件部',
            jobNumber: 'H0071',
            mobile: '15688535383',
            name: '郝志杰',
            userId: 'HaoZhiJie'
        };
        if (use) {
            this.user = use;
            this.getPaperInfo();
        } else {
            // 获取用户信息
            getUse({ code: code }).then(res => {
                if (res.code == 0) {
                    this.user = res.data;
                    window.sessionStorage.setItem('use',JSON.stringify(res.data));
                    this.getPaperInfo();
                } else {
                    this.user = {};
                    this.overlayShow = false;
                    Dialog.alert({message: res.msg});
                }
            }).catch(err => {
                this.overlayShow = false;
                Dialog.alert({message: '获取用户信息失败'});
            });
        }


        // 获取开始答题时间
        this.startTime = date.getTime();
    },
    methods: {
        // 获取试题
        getPaperInfo() {
            let param = {
                answerPerson: this.user.name ,
                department: this.user.department,
                answerDate: this.todays
            };
            getPaper(param).then(res => {
                if (res.code == 0) {
                    this.questionArr = res.data;
                    this.overlayShow = false;
                } else {
                    this.overlayShow = false;
                    this.resultDialog = true;
                    let temporaryStr = res.msg.split('，')[1];
                    let answerArr = temporaryStr.split('-');
                    // 分数 答对 时间
                    this.rightNum = answerArr[0] / 10;
                    this.timeSpent = answerArr[2];
                    this.questionArr = [];
                }
            });
        },
        // 点击选项
        checkAnswer(row, index,options) {
            // 缓存当前题目和答案
            this.nowTitle = row;
            // 单选和判断
            if (row.types == 1 || row.types == 3) {
                this.currentIndexArr = [index];
                this.nowAnswerArr = [options];
            }
            // 多选的逻辑
            if (row.types == 2) {
                // 处理页面显示的逻辑
                if (this.currentIndexArr.indexOf(index) > -1) {
                    // 取消勾选
                    this.currentIndexArr.splice(this.currentIndexArr.indexOf(index),1);
                } else {
                    // 选中
                    this.currentIndexArr.push(index);
                }
                // 处理选项的逻辑{
                if (this.nowAnswerArr.indexOf(options) > -1) {
                    // 取消勾选
                    this.nowAnswerArr.splice(this.nowAnswerArr.indexOf(options),1);
                } else {
                    // 选中
                    this.nowAnswerArr.push(options);
                }
            }
        },
        // 提交
        confirm() {
            this.overlayShow = true;
            let userAnswer = this.nowAnswerArr[0];
            let formalAnswer = this.nowTitle.answer;
            // 判断回答是否正确 多选要拼接答案
            if (this.nowTitle.types == 2) {
                // 先排序再拼接
                this.nowAnswerArr = this.nowAnswerArr.sort((a,b) => {
                    if (a < b) {
                        return -1;
                    }
                    if (a > b) {
                        return 1;
                    }
                    return 0;
                });
                userAnswer = this.nowAnswerArr.join(',');
                // 排序官方答案
                let arr = formalAnswer.split(',');
                formalAnswer = arr.sort((a,b) => {
                    if (a < b) {
                        return -1;
                    }
                    if (a > b) {
                        return 1;
                    }
                    return 0;
                });
            }
            let temporary = JSON.parse(JSON.stringify(this.nowAnswerArr));
            if (userAnswer == formalAnswer) {
                // 如果答对显示正确的样式
                this.isOk = true;
                this.rightNum++;
                temporary.forEach(ele => {
                    this.$set(this.nowTitle, 'answer' + ele, 'right');
                });
            } else {
                this.isOk = false;
                // 答错显示错误的样式
                temporary.forEach(ele => {
                    this.$set(this.nowTitle, 'answer' + ele, 'wrong');
                    // 显示一个正确答案给大家看
                    this.$set(this.nowTitle, 'answer' + this.nowTitle.answer, 'right');
                });
            }
            // 结束时间
            let date = new Date();
            this.endTime = date.getTime();
            this.timeSpent = ((this.endTime - this.startTime) / 1000).toFixed(0);
            // 提交参数
            let param = {
                'answerDate': this.todays,
                'answerPerson': this.user.name ,
                'answerResult': userAnswer,
                'department': this.user.department ,
                'isItRight': this.isOk ? 1 : 0,
                'testQuestionsId': this.nowTitle.id,
                'timeSpent': this.nowIndex == 10 ? this.timeSpent : '',
                'jobNumber': this.user.jobNumber
            };
            // 提交答案
            this.submitAnswer(param);
        },
        // 提交答案
        submitAnswer(param) {
            this.currentIndexArr = [];
            this.nowAnswerArr = [];
            saveAnswer(param).then(res => {
                // 答对了直接下一题
                if (this.isOk) {
                    this.showConfirmBtn = true;
                    setTimeout(() => {
                        this.nextTitle();
                    }, 1000);
                } else {
                    this.showConfirmBtn = false;
                }
                // 最后一题要显示查看结果的按钮
                if (this.nowIndex == 10) {
                    this.resultFlag = true;
                }
                this.overlayShow = false;
            }).catch(err => {
                this.overlayShow = false;
            });
        },
        // 下一题
        nextTitle() {
            // 如果不是最后一题就跳转下一题
            if (this.nowIndex != 10) {
                this.nowIndex++;
            }
            // 隐藏下一题按钮 显示确定按钮
            this.showConfirmBtn = true;
            this.isOk = true;
            // 清除数据 继续下一题
            this.nowAnswerArr = [];
            this.currentIndexArr = [];
        },
        // 显示结果
        showResult() {
            this.resultDialog = true;
            this.showConfirmBtn = true;
            this.currentIndexArr.length = 0;
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='less' scoped>
* {
    padding: 0;
    margin: 0;
}
.main {
    box-sizing: border-box;
    position: relative;
    padding:  2vh;
    max-width: 430px;
    margin: 0 auto;
    overflow: auto;
    width: 100%;
    min-height: 100%;

    background: url('../assets/bg.png') left/cover;
    .content{
        height: 465px;
        padding-bottom: 4vh;
        border-radius:4px;
        background-color: #fff;
    }
    .question-box{
        width:100%;
        .question-header{
            width:100%;
            height: 60px;
            padding: 0 12px 0 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #efe7e7;
            box-sizing: border-box;
            .question-header-left{
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    height: 20px;
                    width: 4px;
                    left:-10px;
                    background-color: #1989fa;
                }
            }
            .index{
                font-size: 30px;
            }
            .total{
                margin-left: 1px;
                color:#b4aeae;
            }
        }
        .question-desc{
            min-height: 100px;
            padding:20px 8px;
            text-align: left;
            font-size: 18px;
            font-weight: bold;
        }
        .question-answer{
            padding:20px 8px;
            .info{
                min-height: 45px;
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                text-align: left;
                border-radius: 4px;
                background-color: #fafafa;
                border:1px solid  #efe7e7;
                padding-left: 10px;
                word-break: break-all;
                .van-icon-close,.van-icon-passed{
                    margin-right: 4px;
                }
            }
            .current-info{
                    border:1px solid  #1989fa;
                    color:#1989fa
            }
            .right-answer{
                border:1px solid  #67C23A;
                color:#fff;
                background-color:#67C23A;
            }
            .wrong-answer{
                border:1px solid  #F56C6C;
                color:#fff;
                background-color:#F56C6C;
            }
        }

    }
    .analysis{
        min-height: 250px;
        margin-top: 10px;
        padding: 10px 0;
        border-radius: 4px;
        background-color: #fff;
        .analysis-line{
            width:100%;
            height: 4px;
            box-sizing: border-box;
            background-color: #efe7e7;
        }
        .analysis-header{
            width:100%;
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 12px 0 20px;
            border-bottom: 1px solid #efe7e7;
            box-sizing: border-box;
            .analysis-header-left{
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    height: 20px;
                    width: 4px;
                    left:-10px;
                    background-color: #1989fa;
                }
            }
        }
        .analysis-content{
            padding: 10px 12px 0 20px;
            text-align: left;
            .analysis-content-right{
                display: block;
                margin-bottom: 10px;
            }
        }
    }

}

.btn-wrap{
    height: 30px;
    margin-bottom: 10px;
    position: relative;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.radius-btn{
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
    margin-bottom: 6px;
    position: absolute;
    right: 0;
    padding: 0 8px;
}
.result{
    width: 280px;
    height: 300px;
    margin: 200px auto 0;
    position: relative;
    border-radius: 4px;
    background: url('../assets/result.jpg') left/contain;
    .result-header{
        position: absolute;
        top: 10px;
        left: 20px;
        text-align: left;
        color:#fff;
        .result-header-info{
            margin-top: 10px;
            font-size: 36px;
        }
    }
    .result-info{
        width:100%;
        position: absolute;
        top: 140px;
        display: flex;
        box-sizing: border-box;
        justify-content: space-around;
        div{
            width: 50%;

        }
        p{
            width:100%;
            text-align: left;
            padding-left: 14px;
            margin-bottom: 30px;
            color:#2ffff8;
        }
    }
    .result-btn{
        width:100%;
        position: absolute;
        top: 260px;
        height: 65px;
        text-align: center;
        .btn{
            height: 30px;
            padding: 0 8px;
            line-height: 30px;
            border-radius: 4px;
        }
    }
}
</style>
