var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[(!_vm.resultFlag)?_c('div',{staticClass:"btn-wrap"},[_c('van-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showConfirmBtn),expression:"showConfirmBtn"}],staticClass:"radius-btn",attrs:{"type":"info","size":"normal","disabled":_vm.currentIndexArr.length == 0},on:{"click":_vm.confirm}},[_vm._v("确 认")]),_c('van-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showConfirmBtn),expression:"!showConfirmBtn"}],staticClass:"radius-btn",attrs:{"type":"info","size":"normal"},on:{"click":_vm.nextTitle}},[_vm._v("下一题")])],1):_c('div',{staticClass:"btn-wrap"},[_c('van-button',{staticClass:"radius-btn",attrs:{"type":"info","size":"normal"},on:{"click":_vm.showResult}},[_vm._v("完 成")])],1),_c('div',{staticClass:"content"},[_c('div',{staticClass:"question-box"},[_c('div',{staticClass:"question-header"},[_c('div',{staticClass:"question-header-left"},[_vm._v(" 题目")]),_c('div',[_c('b',{staticClass:"index"},[_vm._v(_vm._s(_vm.nowIndex))]),_c('span',{staticClass:"total"},[_vm._v("/10")])])]),_vm._l((_vm.questionArr),function(item,index){return _c('div',{key:item.id},[(index + 1 == _vm.nowIndex)?[_c('div',{staticClass:"question-desc"},[_vm._v(" "+_vm._s(item.questionName)+" ")]),_c('div',{staticClass:"question-answer"},[(item.types !==3)?_c('ul',[_c('li',{staticClass:"info",class:{'current-info':_vm.currentIndexArr.includes(0),
                                         'right-answer':item.answerA=='right',
                                         'wrong-answer':item.answerA=='wrong'
                                },on:{"click":function($event){return _vm.checkAnswer(item,0,'A')}}},[(item.answerA=='right')?_c('van-icon',{attrs:{"name":"passed"}}):_vm._e(),(item.answerA=='wrong')?_c('van-icon',{attrs:{"name":"close"}}):_vm._e(),_c('span',[_vm._v("A."+_vm._s(item.optiona))])],1),_c('li',{staticClass:"info",class:{'current-info':_vm.currentIndexArr.includes(1),
                                         'right-answer':item.answerB=='right',
                                         'wrong-answer':item.answerB=='wrong'
                                },on:{"click":function($event){return _vm.checkAnswer(item,1,'B')}}},[(item.answerB=='right')?_c('van-icon',{attrs:{"name":"passed"}}):_vm._e(),(item.answerB=='wrong')?_c('van-icon',{attrs:{"name":"close"}}):_vm._e(),_vm._v(" B."+_vm._s(item.optionb)+" ")],1),_c('li',{staticClass:"info",class:{'current-info':_vm.currentIndexArr.includes(2),
                                         'right-answer':item.answerC=='right',
                                         'wrong-answer':item.answerC=='wrong'
                                },on:{"click":function($event){return _vm.checkAnswer(item,2,'C')}}},[(item.answerC=='right')?_c('van-icon',{attrs:{"name":"passed"}}):_vm._e(),(item.answerC=='wrong')?_c('van-icon',{attrs:{"name":"close"}}):_vm._e(),_vm._v(" C."+_vm._s(item.optionc)+" ")],1),_c('li',{staticClass:"info",class:{'current-info':_vm.currentIndexArr.includes(3),
                                         'right-answer':item.answerD=='right',
                                         'wrong-answer':item.answerD=='wrong'
                                },on:{"click":function($event){return _vm.checkAnswer(item,3,'D')}}},[(item.answerD=='right')?_c('van-icon',{attrs:{"name":"passed"}}):_vm._e(),(item.answerD=='wrong')?_c('van-icon',{attrs:{"name":"close"}}):_vm._e(),_vm._v(" D."+_vm._s(item.optiond)+" ")],1)]):_c('ul',[_c('li',{staticClass:"info",class:{'current-info':_vm.currentIndexArr.includes(0),
                                         'right-answer':item.answerA=='right',
                                         'wrong-answer':item.answerA=='wrong'
                                },on:{"click":function($event){return _vm.checkAnswer(item,0,'A')}}},[(item.answerA=='right')?_c('van-icon',{attrs:{"name":"passed"}}):_vm._e(),(item.answerA=='wrong')?_c('van-icon',{attrs:{"name":"close"}}):_vm._e(),_vm._v(" A.正确 ")],1),_c('li',{staticClass:"info",class:{'current-info':_vm.currentIndexArr.includes(1),
                                         'right-answer':item.answerB=='right',
                                         'wrong-answer':item.answerB=='wrong'
                                },on:{"click":function($event){return _vm.checkAnswer(item,1,'B')}}},[(item.answerB=='right')?_c('van-icon',{attrs:{"name":"passed"}}):_vm._e(),(item.answerB=='wrong')?_c('van-icon',{attrs:{"name":"close"}}):_vm._e(),_vm._v(" B.错误 ")],1)])])]:_vm._e()],2)})],2)]),(!_vm.isOk)?_c('div',{staticClass:"analysis"},[[_vm._m(0),_c('div',{staticClass:"analysis-content"},[_c('b',{staticClass:"analysis-content-right"},[_vm._v("正确答案："+_vm._s(_vm.nowTitle.answer))]),_c('p',[_vm._v(" "+_vm._s(_vm.nowTitle.analysis)+" ")])])]],2):_vm._e(),_c('van-overlay',{attrs:{"show":_vm.overlayShow}},[_c('div',{staticClass:"wrapper"},[_c('van-loading',{attrs:{"type":"spinner"}})],1)]),_c('van-overlay',{attrs:{"show":_vm.resultDialog}},[_c('div',{staticClass:"result"},[_c('div',{staticClass:"result-header"},[_c('div',[_vm._v("今日答对题目数")]),_c('div',{staticClass:"result-header-info"},[_vm._v(_vm._s(_vm.rightNum))])]),_c('div',{staticClass:"result-info"},[_c('div',[_c('p',[_vm._v("正确率："+_vm._s((_vm.rightNum / 10) * 100 + '%'))]),_c('p',[_vm._v("错题数："+_vm._s(10- _vm.rightNum))])]),_c('div',[_c('p',[_vm._v("得分："+_vm._s(_vm.rightNum* 10))]),_c('p',[_vm._v("用时："+_vm._s(_vm.timeSpent)+"秒")])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analysis-header"},[_c('div',{staticClass:"analysis-header-left"},[_vm._v("答案解析")])])
}]

export { render, staticRenderFns }