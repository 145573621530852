/*
 * @Description:
 * @Autor: 郝志杰
 * @Date: 2021-10-28 10:21:00
 * @LastEditors: 刘欢
 * @LastEditTime: 2022-03-19 15:45:23
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Vant from 'vant';
import 'vant/lib/index.css';
const wx = window.wx;
Vue.prototype.$wx = wx;
Vue.use(Vant);

Vue.config.productionTip = false;
import Toast from 'vue-toast-component';
Vue.use(Toast);

new Vue({
    router,
    render: (h) => h(App)
}).$mount('#app');
