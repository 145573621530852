/*
 * @Description:
 * @Autor: 郝志杰
 * @Date: 2023-02-14 10:21:00
 * @LastEditors: 郝志杰
 * @LastEditTime: 2023-02-15 15:17:06
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import paper from '../views/paper.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: paper
    },
    {
        path: '/paper',
        name: 'paper',
        component: paper
    }
];

const router = new VueRouter({
    routes
});

export default router;
